body {
  // overflow-x: hidden !important;

}

.op-ticker {
  width: 3rem;
}

.paypal-box {
  height: 100vh;
}

.paypal-inbox {
  border: 1px solid black;
  max-height: 500px !important;
}

.paypal-inbox div {
  width: 100%;
}

// footer
.main-footer {
  margin-top: auto;
  // min-height: 20vh;

}

.vertical-divider {
  height: auto;
  border-left: 1px solid black;
}

.footer-box span a {
  font-weight: 800 !important;
}

.footer-box .ul-box {
  width: 100%;
  justify-content: flex-end;
}

.nhp-footer-links {
  width: 50%;
  list-style: none;
  gap: 1.4rem;
  text-align: center;
  // justify-content: flex-end;
}

.nhp-footer-link {
  display: block;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.95rem 0.4rem;
  border-radius: 0.358rem;

}

.footer-list-box {
  gap: 3rem;
  justify-content: end;
}

.nhp-footer-link:hover {
  background-color: rgb(108, 93, 211);
  color: #fff !important;
}

// privacy-policy
.privacy-policy {
  margin: auto;
}

.privacy-policy-main {
  height: 100vh;
}

//portfolio
.portfolio-table {
  max-height: 50vh;
  // overflow-y: auto;
  // overflow-x: hidden;

  margin-top: -15px;
}

.scrollable-tbody {
  height: 200px;
  overflow-y: scroll;
}
.dash-notes-eye{
  position: relative;
  margin-top: -1.5rem !important;
  left: calc(100% + 1rem);
}
.dash-notes-toggle {
  
  // background-color: #373638 !important;
  // color: #fff !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  // border: 1px solid #ffffff !important
}
.dash-notes-toggle:focus {
  // background-color: #373638 !important;

}



// .notes-container {
//   opacity: .8;
//   transition: opacity 0.3s ease-in-out;
// }

// .notes-container-visible {
//   opacity: 1;
// }
@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.notes-container {
  // width: 600px !important;
  // position: relative;
  // left: 100%;
  z-index: 999;
  // top: -20px;
  // right: 30px;
  // opacity: 1;
  // transition: opacity 0.3s ease-in-out;
}

.notes-container.anime {
  animation: zoomIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
  transition: none; /* Disable transition during animation */
}
.dash-profile-btn {
  
  background-color: #fff !important;
  color: inherit !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  border: 1px solid #6c5dd3 !important
}
.dash-profile-btn:focus {
  background-color: #fff !important;

}


@media only screen and (min-width: 500px) {
  .paypal-button-container {
    margin-left: auto !important
  }
}

.portfolio-search-btn::-webkit-input-placeholder {

  color: rgba(27, 27, 27, 0.80) !important;
  /* Body/b2 */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

}

.portfolio-search-btn {
  color: black;
  padding-left: 40px;

}

.port-search-box {
  margin-left: -40px;
}




.paginate-btn {
  background-color: #6c5dd3 !important;
}

.clearfilt-btn {
  background-color: #6c5dd3 !important;
}

.rdrCalendarWrapper {
  color: rgba(109, 93, 211, 0.734) !important;
  font-size: 12px;
}

.rdrDateDisplayItemActive {
  border-color: #6d5dd3e9 !important;
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #6d5dd3e9 !important
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: #6d5dd3e9 !important;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

// span color
.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: rgba(109, 93, 211, 0.734);
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  background: rgba(176, 168, 232, 0.29);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid rgba(109, 93, 211, 0.734);
  z-index: 1;
}

.enddate-form label {
  // display: block;
  margin: auto 1rem;
  // text-align: left;
  // width: fit-content!important;
}

@media (min-width: 991.98px) {
  .main-buttons-1 {
    display: none !important;
  }
  
  .notes-container {
    width: 600px !important;
    // position: relative;
    // left: 100%;
    z-index: 999;
    // top: -20px;
    // right: 30px;
    // opacity: 1;
    // transition: opacity 0.3s ease-in-out;
  }
}

@media (max-width: 991.98px) {
  .fixed-bottom {
    position: static !important;
    bottom: 0;
    // z-index: 1030;
  }

  .btn-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .btn-sm-half {
    flex: 0 0 44%;
    max-width: 44%;
  }
  .btn-sm-half-set {
    flex: 0 0 22%;
    max-width: 22%;
  }
  .btn-sm-half-profile {
    flex: 0 0 44%;
    max-width: 20%;
  }

  .btn-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .btn-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .btn-filt {
    display: block;
    width: 50%;
  }

  #trending {
    display: none !important;
  }

  .port-search-box {
    color: black;
    margin: 0;

  }

  .portfolio-search-btn {
    color: black;
    padding: 0.438rem 1rem !important;

  }

  .resetDateFilterbtn {
    margin: 0.438rem 0;

  }

  .search-label {
    margin: 0 !important;
  }

  .port-search-box svg {
    // left: 20% !important;
    display: none !important;
  }

  .DateRangePicker {
    overflow: auto;
  }

  .rdrDefinedRangesWrapper {
    width: 30% !important;
  }

  .rdrCalendarWrapper {
    width: 60%;
    // display:  none !important;
  }

  .main-buttons-0 {
    display: none !important;
  }

  .nhp-footer-links {
    justify-content: center !important;
    // flex-direction: column !important;
  }
}

@media(max-width: 576px) {
  .rdrDefinedRangesWrapper {
    width: 325px !important;
  }
  .js-sm-center {
    justify-content: center !important;
  }
  #endDate {
    display: none;
  }

  .rdrCalendarWrapper {
    display: none !important;
  }
}

@media(max-width: 420px) {
  .enddate-form {
    width: fit-content;
    margin: .2rem 0;
  }

  .m-sm-50 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
    padding: 0.486rem 1.2rem !important;
  }

  .px-sm-1 {
    padding: 0.586rem 1.2rem !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .main-buttons-0 {
    display: none !important;
  }

  #exampleendate {
    margin: 0 !important;
  }

  .btn-sm-half-half {
    flex: 0 0 14%;
    max-width: 24%;
    padding: 0.690rem 1px;
    // background-color: #6c5dd3 !important;
    color: white !important;
  }

  .footer-box {
    flex-direction: column-reverse !important;

  }

  .footer-box .ul-box {
    width: 100%;
    justify-content: center;
  }

  .footer-box .clearfix {
    margin-bottom: 1rem !important;
  }

  .footer-box ul li a {
    // width: 50%;
    padding: 0.3rem 0.1rem;
    text-align: center;
  }

  // .nhp-footer-link {
  //   font-size: 1rem !important;
  // }


  // privacy-policy 
  .privacy-policy {
    margin: 1% 2%;
  }


}

.lead {
  display: block !important;
  font-size: 1.3rem !important;
}

.pricing-card-title {
  font-size: 1.8rem !important;
}

.list-unstyled {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  line-height: 2.5rem !important;
}

.payment-url-box {
  background-color: #ffff;
}